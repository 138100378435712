<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="480" modalWidth="640" @cancel="cancel">
			<div slot="content" layout="vertical">
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.pcb.boardElement.title3')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.pcb.boardElement.content3')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;position: relative;" @mouseover="quantityMoserover" @mouseleave="quantityMouseleave">
							<input class="input1" style="cursor: pointer;" type="text" v-model="boardNumber" placeholder="" :disabled="true" />
							<span class="x">PCS</span>
							<div class="popover" v-if="quantityType==true">
								<el-radio-group v-model="boardNumber">
									<el-radio :label="item.name" v-for="(item,index) in dataList.quantity" :key="index" style="padding: 10px 0;">{{item.name}}</el-radio>
								</el-radio-group>
							</div>
						</div>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title3')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.pcb.boardElementTwo.content3')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="boardNumberPliesIndex" :dataList="dataList.numberBoardLayers" @select="numberBoardLayersSelect"></single-choice>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title9')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.pcb.boardElementTwo.content7')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<single-choice-color :dataIndex="resistanceWeldingColor" :dataList="dataList.resistanceWeldingColor" @select="selectResistanceWeldingColorClick"></single-choice-color>
						</div>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title10')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.pcb.boardElementTwo.content8')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<single-choice-color :dataIndex="characterColor" :dataList="dataList.characterColor" @select="selectCharacterColorClick"></single-choice-color>
						</div>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title11')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.pcb.boardElementTwo.content9')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<single-choice-popup :dataIndex="solderPadSprayCoating" :dataList="dataList.solderPadSprayCoating" @select="selectSolderPadSprayCoatingClick" @dialogSelect="selectDialogSelectClick"></single-choice-popup>
						</div>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">发票类型：</div>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<single-choice :dataIndex="invoiceType" :dataList="dataList.invoiceType"
								@select="selectInvoiceTypeClick"></single-choice>
						</div>
					</div>
				</div>
				
				<div style="display: flex;width: 100%;justify-content: right;padding-top: 20px;" v-if="priceInfo">
					<div>预计总价：</div>
					<div style="color: red;font-weight: bold;">￥{{priceInfo.orderno || "0.00"}}</div>
				</div>
				<div style="color: red;display: flex;justify-content: right;padding-top: 10px;">*订单修改成功后，我们将根据最新的参数值重新审核订单</div>
			</div>
			<template slot="footer">
			  <a-button type="primary" @click="submitForm">
			    保存
			  </a-button>
			  <a-button @click="cancel">
			    取消
			  </a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import modifyModel from './modifyModel'
	export default {
		...modifyModel
	}
</script>

<style lang="less" scoped>
	.be_title{
		display: flex;
		// align-items: center;
		width:20%;
		
		.be_mandatory{
			font-size: 1vw;
			color: red;
		}
		
		.be_text{
			color: #888;
			font-size: 0.8vw;
		}
	}
	
	.input1{
		width: 5vw;
		height: 2vw;
		line-height: 2vw;
		text-align: center;
		border: 1px solid #d6d6d6;
		background: #fff;
	}
	
	.x{
		display: inline-block;
		font-size: 0.8vw;
		border: 1px solid #d6d6d6;
		text-align: center;
		background: #efefef;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
	
	.popover{
		position: absolute;
		top:32px;
		left: 0;
		width: 400px;
		border: 1px solid #d6d6d6;
		background: #fff;
		padding: 20px;
		z-index: 2;
	}
</style>
