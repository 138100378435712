<template>
	<div>
		<div class="selectionBox">
			<div class="sb_forDiv" v-for="(item,index) in dataList1" :key="index" v-if="item.isdele==false" @click="selectBoxClick(item,index)">
				<!-- <el-image style="position: absolute;bottom:0px;right: 0;width: 14px;height: 14px;" v-if="dataIndex1==index" :src="require('@/assets/img/choose-active@ico.png')"></el-image> -->
				<div class="sb_fd_text" :style="dataIndex1==index?'border: 1px solid red;' : ''" v-if="$i18n.locale=='cn'">{{item.name}}</div>
				<div class="sb_fd_text" :style="dataIndex1==index?'border: 1px solid red;' : ''" v-if="$i18n.locale=='en'">{{item.enname}}</div>
			</div>
		</div>

		<!-- 添加或修改学生信息对话框 -->
		<el-dialog :title="title" :visible.sync="open" :width="windowWidth>=800 ? '600px' : '300px'" append-to-body>
			<div class="selectionBox">
				<div class="sb_forDiv" v-for="(item,index) in dialogList" :key="index"
					@click="dialogSelectBoxClick(item,index)">
					<!-- <el-image style="position: absolute;bottom:0px;right: 0;width: 14px;height: 14px;"  v-if="dataList1[dataIndex1].value==index" :src="require('@/assets/img/choose-active@ico.png')"></el-image> -->
					<div class="sb_fd_text" :style="dataList1[dataIndex1].child==index?'border: 1px solid red;' : ''" v-if="$i18n.locale=='cn'">{{item.name}}</div>
					<div class="sb_fd_text" :style="dataList1[dataIndex1].child==index?'border: 1px solid red;' : ''" v-if="$i18n.locale=='en'">{{item.enname}}</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import dictApi from '@/api/system/dict'
	export default {
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
			dataIndex: {
				type: Number,
				default: null
			}
		},
		data() {
			return {
				title: null, //弹框标题
				open: false, //是否打开弹框
				dialogList: null, //弹框列表数据
				dataIndex1:this.dataIndex,
				dataList1:this.dataList,
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},

		created() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},

		mounted() {
			
		},

		watch: {
			dataIndex(val){
				this.dataIndex1=val;
			},
			dataList(val){
				// console.log("val",val)
				this.dataList1 = val;
				if(this.dataList1[this.dataIndex1].type==false){
					var a = 0;
					this.dataList1.forEach(res=>{
						if(res.type==true){
							this.dataIndex1 = a
							return this.dataIndex1;
						}else{
							a = a+1
						}
					})
				}
			}
		},

		computed: {

		},

		methods: {
			//--------------------单选/多选框选择------------------------------------
			selectBoxClick: function(item, index) {
				console.log(item)
				this.dataIndex1 = index;
				dictApi.getDictsByPid({
					pid: item.id
				}).then((res) => {
					console.log(res)
					if(res.content[0].length>0){
						this.open = true;
						this.dialogList = res.content[0];
					}
					this.$emit('select', index)
				})
			},

			//--------------------弹框单选/多选框选择------------------------------------
			dialogSelectBoxClick: function(item, index) {
				console.log(this.dataList1[this.dataIndex1])
				this.dataList1[this.dataIndex1].child = index;
				this.$emit('dialogSelect', index)
			},

			//-------------------确认选择--------------------------------
			submitForm: function() {
				this.open=false;
			}
		}
	}
</script>

<style lang="less" scoped>
	.selectionBox {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.sb_forDiv {
			position: relative;
			margin-right: 10px;
			cursor: pointer;

			.sb_fd_text {
				padding: 6px 12px;
				border: 1px solid #dfe1e6;
				background: #fff;
				color: #888;
				font-size: 10px;
				margin-bottom: 10px;
			}
		}
	}

	.sb_forDiv:hover .sb_fd_text {
		border: 1px solid red;
	}
</style>
