import request from '@/utils/request'
export default {
// 通过父ID获取字典
  getDictByPid(data) {
    return request({
      url: '/dict/getDictByPid',
      method: 'post',
      data: data
    })
  },
  // 通过父ID获取字典
  getDictsByPid(data) {
    return request({
      url: '/dict/getDictsByPid',
      method: 'post',
      data: data
    })
  },
  // 通过父ID获取字典列表
  getDictListByPid(data) {
    return request({
      url: '/dict/getDictListByPid',
      method: 'post',
      data: data
    })
  },
  // 新增字典
  newDict(data) {
    return request({
      url: '/dict/newDict',
      method: 'post',
      data: data
    })
  },
  // 更新字典
  updateDict(data) {
    return request({
      url: '/dict/updateDict',
      method: 'post',
      data: data
    })
  }
}
