import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
import singleChoice from '@/components/singleChoice/index.vue';
import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
import { calcPcbPrice } from '@/api/home/index.js' 
import {
		updateOrder
	} from '@/api/orderManagement/index'
import {
		dataInJs
	} from '@/business/valuation/components/data.js';
export default {
	components: {
		singleChoice,
		changeSingleChoice,
		singleChoiceImage,
		singleChoiceColor,
		singleChoicePopup,
		AntModal,
		CButton
	},
	props:{
		open: { // 按钮默认文本
		  type: Boolean,
		  default: false
		},
		dataInfo:{
			type: Object,
			default: ()=>{}
		},
	},
	data() {
		return {
			dataList: dataInJs(), //list数据
			modalOpen: this.open,//弹窗打开关闭
			formTitle: "修改产品", //弹框名
			customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
			boardNumber:'',//板子数量
			boardNumberPliesIndex:0,//板子层数
			resistanceWeldingColor:0,//焊接颜色下标
			characterColor:0,//字符颜色下标
			solderPadSprayCoating:0,//焊盘喷镀下标
			invoiceType: 0, //发票类型下标
			goldthick:0,//焊盘喷镀popup
			quantityType:false,//是否打开数量弹框
			priceInfo:null,//订单价格
		}
	},

	created() {
		// console.log(this.modifyOrderInfo)
	},

	mounted() {
		
	},

	watch: {
		open(val){
			// console.log(this.dataInfo)
			this.boardNumber = this.dataInfo.pcbInfoList[0].pcbcount;
			this.boardNumberPliesIndex = this.dataInfo.pcbInfoList[0].pcblayer - 1;
			this.resistanceWeldingColor = this.dataInfo.pcbInfoList[0].pcbholecolor - 1;
			this.characterColor = this.dataInfo.pcbInfoList[0].pcbfontcolor - 1;
			this.solderPadSprayCoating = this.dataInfo.pcbInfoList[0].pcbholespray - 1;
			this.invoiceType = this.dataInfo.invoicetype - 1;
			this.calcPcbPrice()
		}
	},

	computed: {

	},

	methods: {
		//------------------------取消按钮-------------------------------
		cancel: function() {
			// console.log("cancel")
			this.modalOpen = false;
			this.$emit('close');
		},
		
		//-------------是否打开数量弹框------------------------------
		quantityMoserover:function(){
			this.quantityType=true;
		},
		
		//---------------关闭数量弹框-----------------------------
		quantityMouseleave:function(){
			this.quantityType=false;
		},
		
		//--------------------层数单选框回调---------------------------------
		numberBoardLayersSelect:function(e){
			this.boardNumberPliesIndex=e;
			this.calcPcbPrice()
			// console.log(this.boardNumberPliesIndex)
		},
		
		//-----------------焊接颜色-----------------------------------
		selectResistanceWeldingColorClick:function(e){
			this.resistanceWeldingColor = e;
			this.calcPcbPrice()
			// calcPcbPriceReturn()
		},
		
		//----------------字符颜色单选回调----------------------------
		selectCharacterColorClick:function(e){
			this.characterColor = e;
			this.calcPcbPrice()
			// calcPcbPriceReturn()
		},
		
		//-----------------焊盘喷镀-------------------------------
		selectSolderPadSprayCoatingClick:function(e){
			this.solderPadSprayCoating = e;
			this.calcPcbPrice()
		},
		
		//----------------焊盘喷镀popup------------------------
		selectDialogSelectClick:function(e){
			this.goldthick = e;
			this.calcPcbPrice()
		},
		
		//--------------------------发票类型单选回调-----------------------------
		selectInvoiceTypeClick: function(e) {
			this.invoiceType = e;
			this.calcPcbPrice()
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			this.$confirm('是否保存修改的订单？', '修改订单', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
			  let data={
			  	pcbInfo:[{
			  		id:this.dataInfo.pcbInfoList[0].id,
			  		pcbcount:this.boardNumber,
			  		pcblayer:this.boardNumberPliesIndex + 1,
			  		pcbholecolor:this.resistanceWeldingColor + 1,
			  		pcbfontcolor:this.characterColor + 1,
			  		pcbholespray:this.solderPadSprayCoating  + 1,
			  	}],
				orderInfo:{
					invoicetype:this.invoiceType  + 1,
					id:this.dataInfo.id,
					totalprice: this.priceInfo.orderno,
					pcbprice: this.priceInfo.orderno,
					engineerfee: this.priceInfo.engineerfee,
					sprayfee: this.priceInfo.sprayfee,
					panelfee: this.priceInfo.panelfee,
					testfee: this.priceInfo.testfee,
					taxationfee: this.priceInfo.taxationfee,
					urgentfee: this.priceInfo.urgentfee,
					specfee: this.priceInfo.specfee,
					discountfee: this.priceInfo.discountfee,
					otherprice: this.priceInfo.otherprice,
					// orderstatus: 0
				}
			  }
			  updateOrder(data).then(res=>{
			  	this.$message.success(
			  		'修改成功',
			  	)
			  	this.modalOpen = false
			  	this.$emit('ok')
			  })
			}).catch(() => {});
		},
		
		//----------------------计算价格接口------------------------
		calcPcbPrice:function(){
			let data={
				pcbcount:this.boardNumber,
				pcbdesigncount:this.dataInfo.pcbInfoList[0].pcbdesigncount,
				pcbtype:this.dataInfo.pcbInfoList[0].pcbtype,
				pcblayer:this.boardNumberPliesIndex + 1,
				pcbwidth:this.dataInfo.pcbInfoList[0].pcbwidth,
				pcblength:this.dataInfo.pcbInfoList[0].pcblength,
				invoicetype: this.invoiceType  + 1,
				invoiceinfo: this.dataInfo.invoiceinfo,
				// heatfactor:1,
				// alstructure:1,
				orderattr: "1",
				pcbsellmodel: this.dataInfo.pcbInfoList[0].pcbsellmodel,
				pcbbrand:this.dataInfo.pcbInfoList[0].pcbbrand,
				pcbthick:this.dataInfo.pcbInfoList[0].pcbthick,
				pcbminlinewidth:this.dataInfo.pcbInfoList[0].pcbminlinewidth,
				pcbminhole:this.dataInfo.pcbInfoList[0].pcbminhole,
				pcbholecolor:this.resistanceWeldingColor + 1,
				pcbfontcolor:this.characterColor + 1,
				pcbholespray:this.solderPadSprayCoating  + 1,
				pcbweldcover:this.dataInfo.pcbInfoList[0].pcbweldcover,
				pcbcuthick:this.dataInfo.pcbInfoList[0].pcbcuthick,
				pcbgoldthick:this.dataInfo.pcbInfoList[0].pcbgoldthick,
				pcbspectech:this.dataInfo.pcbInfoList[0].pcbspectech,
				pcbtestmethod:this.dataInfo.pcbInfoList[0].pcbtestmethod,
			}
			calcPcbPrice(data).then(res=>{
				this.priceInfo=res.data;
				// console.log("calcPcbPrice",res)
			})
		}
	}
}
