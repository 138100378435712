import request from '@/utils/request'

//订单管理列表接口
export function pageByField(data) {
  return request({
    url: '/orderInfo/pageByField',
    method: 'post',
	data:data
  })
}

//订单管理详情接口
export function openQuotePage(data) {
  return request({
    url: '/orderInfo/openQuotePage',
    method: 'get',
	params:data
  })
}

//订单管理修改接口
export function updateOrder(data) {
  return request({
    url: '/orderInfo/updateOrder',
    method: 'post',
	data:data
  })
}

//订单管理取消和删除接口
export function batchOrder(data) {
  return request({
    url: '/orderInfo/batchOrder',
    method: 'post',
	data:data
  })
}

//销售新增bom订单接口
export function saveBom(data) {
  return request({
    url: '/orderInfo/saveBom',
    method: 'post',
	data:data
  })
}