<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="520" modalWidth="750"
			@cancel="cancel">
			<div slot="content" layout="vertical">
				<a-descriptions title="" size="small" bordered v-if="details">
					<a-descriptions-item label="订单编号">
						{{details.orderno}}
					</a-descriptions-item>
					<a-descriptions-item label="下单时间">
						{{filterTime(details.createdate)}}
					</a-descriptions-item>
					<a-descriptions-item label="板子长宽">
						{{details.pcbInfoList[0].pcblength}}x{{details.pcbInfoList[0].pcbwidth}}cm {{dataList.plateTG[details.pcbInfoList[0].pcbbrand-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="板子数量">
						{{details.pcbInfoList[0].pcbcount}} PCS
					</a-descriptions-item>
					<a-descriptions-item label="板子面积">
						{{dataList.minimumThroughHole[details.pcbInfoList[0].pcbminhole-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="板子层数">
						{{dataList.numberBoardLayers[details.pcbInfoList[0].pcblayer-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="设计文件数">
						{{details.pcbInfoList[0].pcbdesigncount}}
					</a-descriptions-item>
					<a-descriptions-item label="阻焊颜色">
						{{dataList.resistanceWeldingColor[details.pcbInfoList[0].pcbholecolor-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="字符颜色">
						{{dataList.characterColor[details.pcbInfoList[0].pcbfontcolor-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="板子厚度">
						{{dataList.boardThickness[details.pcbInfoList[0].pcbcuthick-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="最小线宽/线距">
						{{dataList.minimumLineWidth[details.pcbInfoList[0].pcbminlinewidth-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="过 孔">
						{{dataList.minimumThroughHole[details.pcbInfoList[0].pcbminhole-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="铜箔厚度">
						{{dataList.copperThickness[details.pcbInfoList[0].pcbcuthick-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="飞针测试" :span="2">
						{{dataList.text[details.pcbInfoList[0].pcbtestmethod-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="焊盘喷镀" :span="1.5">
						{{dataList.solderPadSprayCoating[details.pcbInfoList[0].pcbholespray-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="阻焊覆盖" :span="1.5">
						{{dataList.resistanceWeldingCoverage[details.pcbInfoList[0].pcbweldcover-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="交货日期" :span="3">
					</a-descriptions-item>
					<a-descriptions-item label="发票类型" :span="3">
						{{dataList.invoicingMethod[details.invoicetype-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="下单联系人" :span="1">
						{{details.ordername}}
					</a-descriptions-item>
					<a-descriptions-item label="联系人手机" :span="2">
						{{details.orderphone}}
					</a-descriptions-item>
					<a-descriptions-item label="快递类型" :span="1">
						{{details.delivertype}}
					</a-descriptions-item>
					<a-descriptions-item label="快递费用" :span="2">
						{{details.deliverprice}}
					</a-descriptions-item>
					<a-descriptions-item label="收货人地址" :span="3">
						{{details.address}}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions title="费用明细" size="small" bordered v-if="details">
					<a-descriptions-item label="合计">
						{{details.totalprice}}
					</a-descriptions-item>
					<a-descriptions-item label="工程费">
						{{details.engineerfee}}
					</a-descriptions-item>
					<!-- <a-descriptions-item label="合拼费">
						0.00
					</a-descriptions-item> -->
					<a-descriptions-item label="板费">
						{{details.boardfee}}
					</a-descriptions-item>
					<a-descriptions-item label="菲林费">
						{{details.filmfee}}
					</a-descriptions-item>
					<a-descriptions-item label="喷镀费">
						{{details.sprayfee}}
					</a-descriptions-item>
					<a-descriptions-item label="颜色费">
						{{details.colorfee}}
					</a-descriptions-item>
					<a-descriptions-item label="测试费">
						{{details.testfee}}
					</a-descriptions-item>
					<a-descriptions-item label="加急费">
						{{details.urgentfee}}
					</a-descriptions-item>
					<a-descriptions-item label="税费">
						{{details.taxationfee}}
					</a-descriptions-item>
					<!-- <a-descriptions-item label="快递费">
						0.00
					</a-descriptions-item> -->
					<a-descriptions-item label="其它费用">
						{{details.otherprice}}
					</a-descriptions-item>
				</a-descriptions>
			</div>
			<template slot="footer">
				<a-button @click="cancel">
					取消
				</a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
	import moment from 'moment'
	import {
		dataInJs
	} from '@/business/valuation/components/data.js';
	export default {
		components: {
			AntModal
		},
		props: {
			open: { // 按钮默认文本
				type: Boolean,
				default: false
			},
			details: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				modalOpen: this.open, //弹窗打开关闭
				formTitle: "修改产品", //弹框名
				customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
				dataList: dataInJs(), //字典list数据
			}
		},

		created() {

		},

		mounted() {

		},

		methods: {
			//------------------------取消按钮-------------------------------
			cancel: function() {
				// console.log("cancel")
				this.modalOpen = false;
				this.$emit('close');
			},

			//------------------------提交按钮--------------------------------
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.$message.success(
							'修改成功',
						)
						this.open = false
						this.$emit('ok')
					} else {
						return false
					}
				})
			},
			
			//------------------时间戳翻译-----------------------
			filterTime(time){
				return moment(time).format("YYYY-MM-DD HH:mm:ss")
			},
		}
	}
</script>

<style lang="less" scoped>

</style>